<template>
  <v-container fluid>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="msaBlue white--text"> Fail Reason</v-card-title>

        <v-card-text>
          {{ failReason }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row align="center">
      <v-col cols="12" md="6"> </v-col>
      <v-spacer />
      <v-col class="shrink">
        <StandardFilter
          :default="defaultFilters"
          :fields="filterFields"
          :filters="filters"
          @apply="applyFilters($event)"
        />
      </v-col>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="exportRecords"
              v-on="on"
              color="msaBlue"
              class="white--text"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          Export
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text">
            User Sync Records
            <v-badge
              :content="recordsCount"
              :value="recordsCount"
              bordered
              class="ml-2"
              color="msaBlue"
              inline
            >
            </v-badge>
          </v-card-title>
          <v-data-table
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            :headers="tableHeaders"
            :items="records"
            :options.sync="options"
            :server-items-length="recordsCount"
            must-sort
          >
            <template v-slot:[`item.syncGuid`]="{ item }">
              <span
                @click="goToSyncDetail(item.syncGuid)"
                class="msaBlue--text"
                style="cursor: pointer"
              >
                {{ item.syncGuid }}
              </span>
            </template>
            <template v-slot:[`item.clientOs`]="{ item }">
              {{ item.clientOs | removeBuild }}
            </template>
            <template v-slot:[`item.startedAt`]="{ item }">
              {{
                item.startedAt
                  | filterAsLocalDateTime((mask = 'yyyy-LL-dd h:mm a'))
              }}
            </template>
            <template v-slot:[`item.endedAt`]="{ item }">
              {{
                item.endedAt
                  | filterAsLocalDateTime((mask = 'yyyy-LL-dd h:mm a'))
              }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                v-if="isInternalAdmin && item.status == 'Failed'"
                :color="calculateColor(item.status)"
                label
                small
                text-color="white"
                @click="showFailReason(item)"
              >
                {{ item.status }}
              </v-chip>
              <v-chip
                v-else
                :color="calculateColor(item.status)"
                label
                small
                text-color="white"
              >
                {{ item.status }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StandardFilter from '@/components/Filters/StandardFilter.vue';
export default {
  name: 'UserSyncLogsPage',
  components: {
    StandardFilter,
  },
  data() {
    return {
      defaultSortBy: 'startedAt',
      defaultFilters: {
        dateFrom: '',
        dateTo: '',
        status: ['Complete', 'Partial', 'Failed'],
      },
      filters: {
        dateFrom: '',
        dateTo: '',
        status: ['Complete', 'Partial', 'Failed'],
      },
      filterFields: [
        {
          type: 'date-range',
          title: 'Synced',
          key: { from: 'dateFrom', to: 'dateTo' },
        },
        {
          type: 'multi-select',
          title: 'Status',
          key: 'status',
          options: [
            { title: 'Complete', value: 'Complete' },
            { title: 'Parial', value: 'Partial' },
            { title: 'Failed', value: 'Failed' },
          ],
        },
      ],
      options: {
        sortBy: [],
        sortDesc: [true],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
      records: [],
      recordsCount: 0,
      failReason: '',
      dialog: false,
      tableHeaders: [
        {
          text: 'Device ID',
          value: 'deviceGuid',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
          width: '320px',
          sortable: false,
        },
        {
          text: 'Device Info',
          value: 'deviceInfo',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
          sortable: false,
        },
        {
          text: 'Release',
          value: 'clientOs',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
          width: '100px',
          sortable: false,
        },
        {
          text: 'Sync ID',
          value: 'syncGuid',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
          width: '320px',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '100px',
        },
        {
          text: 'Started At',
          value: 'startedAt',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '180px',
        },
        {
          text: 'Ended At',
          value: 'endedAt',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '180px',
        },
      ],
    };
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        const changed = Object.keys(oldVal).some(
          (key) => oldVal[key].toString() != newVal[key].toString(),
        );

        if (changed) {
          this.updateUrl();
        }
      },
      deep: true,
    },
  },
  filters: {
    removeBuild(value) {
      return value.split('(')[0].trim();
    },
  },
  async mounted() {
    this.options.sortBy[0] = this.defaultSortBy;
    this.getRouteQueries();
    await this.getRecords();
    this.getUserDetails();
  },
  computed: {
    isInternalAdmin() {
      return (
        this.$store.state.user.type == this.$constants.USER_TYPES.INTERNAL_ADMIN
      );
    },
  },
  methods: {
    showFailReason(syncRecord) {
      this.failReason = 'Loading reason...';
      this.dialog = true;
      var url = 'get-app-sync-fail-reason?format=json';
      var params = { id: syncRecord.id };
      this.$axios
        .post(url, params)
        .then((response) => {
          this.failReason = response.data;
        })
        .catch(() => {
          this.failReason = 'Failed to load reason.';
        });
    },
    calculateColor(status) {
      switch (status) {
        case 'Complete':
          return 'green';
        case 'Partial':
          return 'orange';
        case 'Failed':
          return 'red';
        default:
          return 'grey';
      }
    },
    updateUrl() {
      let params = this.$helpers.serializeFilter(
        this.filters,
        this.defaultFilters,
      );
      const options = this.$helpers.serializeOptions(
        this.options,
        this.defaultSortBy,
        true,
      );
      params = { ...params, ...options };

      this.$router
        .replace({ name: this.$route.name, query: params })
        .catch(() => {});
    },
    getUserDetails() {
      const params = {
        employeeId: this.$route.params.id,
      };
      if (params.employeeId == this.$store.getters.selectedUser.id) {
        return;
      }

      // Clear the user's name in the breadcrumbs, and then update them
      this.$store.commit('updateSelectedUser', {
        firstName: '',
        lastName: '',
      });

      const url = 'get-user-details?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.userInformation = { ...response.data };
          this.$store.commit('updateSelectedUser', {
            id: this.userInformation.id,
            username: this.userInformation.username,
            firstName: this.userInformation.firstName,
            lastName: this.userInformation.lastName,
            status: this.userInformation.status,
          });
        })
        .catch((error) => error);
    },
    getRouteQueries() {
      const query = this.$route.query;

      this.filters = this.$helpers.deserializeFilters(
        query,
        this.defaultFilters,
      );
      this.options = this.$helpers.deserializeOptions(
        query,
        this.defaultSortBy,
        true,
      );
    },
    applyFilters(filters) {
      this.filters = JSON.parse(JSON.stringify(filters));
      this.options.page = 1;
      this.updateUrl();
    },
    exportRecords() {
      const params = {
        id: this.$route.params.id,
        options: this.options,
        filters: this.filters,
        loaderText: 'Exporting...',
      };
      let url = 'export-app-syncs-by-user?format=json';
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };
      this.$axios
        .post(url, params, options)
        .then((response) => {
          this.$helpers.downloadFile(response.data, 'user sync records.xlsx');
        })
        .catch((error) => error);
    },
    getRecords() {
      const params = {
        options: this.options,
        filters: this.filters,
        loaderText: 'Loading...',
        id: this.$route.params.id,
      };
      let url = 'get-app-syncs-by-user?format=json';
      return this.$axios
        .post(url, params)
        .then((response) => {
          this.records = response.data.items;
          this.recordsCount = response.data.count;
        })
        .catch((error) => error);
    },
    goToSyncDetail(syncGuid) {
      this.$store.commit('updateSelectedSyncGuid', syncGuid);
      this.$router.push({
        name: 'SyncDetails',
        params: { guid: syncGuid },
      });
    },
  },
};
</script>

<style></style>
